import React, { useCallback, useEffect, useRef, useState } from "react";
import { ErrorBoundary } from "@sentry/react";
import Config from "../../config";
import { ReactComponent as StarIcon } from '../../../src/assets/StarIcon.svg'
import { createCallRating } from '../../services/call-rating.service'
import { CircularProgress } from "@mui/material";
import { useCall } from "src/call-sdk/context/CallContext";
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import useLogout from "src/hooks/useLogout";
import LocalStorage from "../../call-sdk/lib/local-storage";
import StorageContants from "../../config/storage.constants";
import useReactPath from "src/hooks/useReactPath";
import { HeaderContainer, Container, TextContainer, StarWrapper, StarsContainer, SkipButton, RatingContainer, SkipButtonContainer } from "./CustomerRatingStyles"

const debouncedFunction = _.debounce(async (star, ratingDetail) => {
  const ratingDetailForPayload = Object.create(ratingDetail);

  const payload = {
    requestId: ratingDetailForPayload.requestId as string,
    rating: star,
    comment: '',
    providerId: ratingDetailForPayload.providerName as string,
    callQualityRating: star,
    callQualityComment: ''
  };

  await createCallRating(payload);

  const feedbackUrl = process.env.REACT_APP_HUBSPOT_FEEDBACK_URL;
  if (feedbackUrl) {
    window.location.replace(feedbackUrl);
  }
}, 1000);

const CustomerRating = () => {
  const call = useCall();
  const { ratingDetail } = call;
  const logoutUser = useLogout();
  const currentPath = useReactPath();
  const navigate = useNavigate();
  const [rating, setRating] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const regionConfig = Config[`${process.env.REACT_APP_REGION}`];
  const hasLoggedOut = useRef(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  //saves ratingDetail to local storage whenever it chnages
  useEffect(() => {
    if (ratingDetail) {
      localStorage.setItem('ratingDetail', JSON.stringify(ratingDetail));
    }
  }, [ratingDetail]);

  //retreive ratigDetail from local storage
  const getRatingDetailFromLocalStorage = () => {
    const storedRatingDetail = localStorage.getItem('ratingDetail');

    return storedRatingDetail ? JSON.parse(storedRatingDetail) : null;
  };

  const storedRatingDetail = getRatingDetailFromLocalStorage();

  useEffect(() => {
    const performLogout = async () => {
      const loggedOut = await logoutUser();

      if (loggedOut) {
        console.log("user is logged out");
      } else {
        navigate({ pathname: "/qr-error" }, { replace: true });
      }
    };

    const localData = LocalStorage.get(StorageContants.AUTH_API_TOKEN);

    if (localData && !hasLoggedOut.current) {
      hasLoggedOut.current = true;

      performLogout();
    }
  }, [logoutUser, navigate]);

  const debouncedRatingCall = useCallback(
    (star: any) => {
      debouncedFunction(star, storedRatingDetail);
    },
    [storedRatingDetail]
  );

  useEffect(() => {
    if (rating > 0) {
      debouncedRatingCall(rating);
    }
  }, [rating, debouncedRatingCall]);

  useEffect(() => {
    const disableBackButton = () => {
      if (currentPath !== "/qr-feedback") {
        navigate({ pathname: "/qr-error" }, { replace: true });
      }
    };

    window.addEventListener("popstate", disableBackButton);

    return () => {
      window.removeEventListener("popstate", disableBackButton);
    };
  }, [currentPath, navigate]);

  return (
    <ErrorBoundary>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", backgroundColor: "white" }}>
          <CircularProgress color="success" />
        </div>

      ) : (
        <Container>
          <HeaderContainer id="header-container">
            <img
              src={regionConfig.appLogoImageSrc}
              alt="Convo Logo"
              id="logo-image"
              width="100%"
              height={regionConfig.appLogoImageHeight}
            />
          </HeaderContainer>
          <RatingContainer>
            <TextContainer>
              <b>{regionConfig.ratingScreenText}</b>
            </TextContainer>
            <StarsContainer>
              {[1, 2, 3, 4, 5].map((star) => (
                <StarWrapper
                  key={star}
                  selected={rating >= star}
                  onClick={() => setRating(star)}
                  aria-label={`${star} star${star > 1 ? 's' : ''}`} 
                >
                  <StarIcon />
                </StarWrapper>
              ))}
            </StarsContainer>
          </RatingContainer>
          <SkipButtonContainer>
            <SkipButton onClick={() => {
              const marketingUrl = process.env.REACT_APP_MARKETING_URL;

              if (marketingUrl) {
                window.location.replace(marketingUrl);
              }}}
              aria-label="Skip rating"
              >
              Skip
            </SkipButton>
          </SkipButtonContainer>
        </Container>
      )}
    </ErrorBoundary>
  );
};

export default CustomerRating;