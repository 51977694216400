import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import SafariIcon from "../../../../assets/Help/Safari.svg";
import ChromeIcon from "../../../../assets/Help/Chrome.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Safari from "./safari/SafariContent";
import Chrome from "./chrome/ChromeContent";

// Type definition for DownArrowProps
type DownArrowProps = {
  expanded: boolean;
};

// Styled components
const HeadContainer = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: bold;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Source Sans Pro";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 118%; /* 28.32px */
`;

const ContentContainer = styled.div`
  color: #000;
  font-feature-settings: "clig" off, "liga" off;

  /* Body/Body 1 Regular */
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;

const AccordionLabel = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px; /* Adjust as needed */
`;

const Icon = styled.img`
  margin-right: 8px;
`;

const DownArrow = styled.span<DownArrowProps>`
  margin-left: auto;
  margin-right: 8px;
  transition: transform 0.3s;
  transform: ${({ expanded }) =>
    expanded ? "rotate(180deg)" : "rotate(0deg)"};
`;

const IOSHelpContent = () => {
  const [activeAccordion, setActiveAccordion] = useState<string | false>(false);

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setActiveAccordion(isExpanded ? panel : false);
    };

  return (
    <React.Fragment>
      <HeadContainer>Camera or Microphone is blocked</HeadContainer>
      <ContentContainer>
        If your camera or microphone is blocked, it may be because they don’t
        have the needed permissions to function.
      </ContentContainer>
      <br />
      <ContentContainer>
        To give them permissions to function in Safari and Chrome, please follow
        these steps:
      </ContentContainer>
      <br />
      <div>
        <Accordion
          expanded={activeAccordion === "safari"}
          onChange={handleAccordionChange("safari")}
        >
          <AccordionSummary
            expandIcon={
              <DownArrow expanded={activeAccordion === "safari"}>
                {activeAccordion === "safari" ? (
                  <KeyboardArrowUpIcon aria-label="Collapse Safari Accordion"/>
                ) : (
                  <KeyboardArrowDownIcon aria-label="Expand Safari Accordion"/>
                )}
              </DownArrow>
            }
            aria-controls="safari-content"
            id="safari-header"
          >
            <AccordionLabel>
              <Icon src={SafariIcon} alt="Safari Icon" />
              <Typography>Safari</Typography>
            </AccordionLabel>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Safari />
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={activeAccordion === "chrome"}
          onChange={handleAccordionChange("chrome")}
        >
          <AccordionSummary
            expandIcon={
              <DownArrow expanded={activeAccordion === "chrome"}>
                {activeAccordion === "chrome" ? (
                  <KeyboardArrowUpIcon aria-label="Collapse Chrome Accordion"/>
                ) : (
                  <KeyboardArrowDownIcon aria-label="Expand Chrome Accordion"/>
                )}
              </DownArrow>
            }
            aria-controls="chrome-content"
            id="chrome-header"
          >
            <AccordionLabel>
              <Icon src={ChromeIcon} alt="Chrome Icon" />
              <Typography>Chrome</Typography>
            </AccordionLabel>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Chrome />
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </React.Fragment>
  );
};

export default IOSHelpContent;
