import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react"; // Import useLayoutEffect
import SelfView from "../selfView/SelfView";
import { CircularProgress, Fab } from "@mui/material";
import { useCall } from "../../call-sdk/context/CallContext";
import { useNavigate } from "react-router-dom";
import { useTwilioUserMediaContext } from "src/call-sdk/context/TwilioUserMediaContext";
import {
  ConnectingContainer,
  Container,
  EndButtonContainer,
  QueueTextContainer,
} from "../connecting/ConnectingStyles";
import cancelIcon from "../../assets/Room/cancel.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useToast } from "src/context/ToastContext";
import { useRoomContext } from "src/call-sdk/context/RoomContext";
import ErrorBoundary from "../ErrorBoundary";
import { datadogRum } from "@datadog/browser-rum";
import EventTracker from "src/pendo/EventTracker";

const Connecting = () => {
  const {
    business,
    getVideoInterpreter,
    isJoining,
    join,
    isCallRejected,
    reject,
    connected,
    roomName,
    error,
    apiErrorFlag,
    twilioVideoToken,
    region,
    isWebTokenExpired,
    position
  } = useCall();

  const { connectToRoom, room, connecting } = useRoomContext();
  const navigate = useNavigate();
  const interpreterFetchedRef = useRef(false);
  const { destroyMedia } = useTwilioUserMediaContext();
  const toast = useToast();

  useLayoutEffect(() => {
    if (!interpreterFetchedRef.current && business?.phoneNumber) {
      getVideoInterpreter(business.phoneNumber);
      interpreterFetchedRef.current = true;
    }
  }, [business?.phoneNumber, getVideoInterpreter]);

  useEffect(() => {
    if (isCallRejected) {
      datadogRum.addAction("Call Rejected navigating to ( /qr-business) ");
      navigate({ pathname: "/qr-business" }, { replace: true });
    }
  }, [isCallRejected, navigate]);

  useEffect(() => {
    if (isJoining && roomName) {
      join();
    }
  }, [isJoining, roomName, join]);

  useEffect(() => {
    if (error && error?.length > 0 && apiErrorFlag) {
      destroyMedia();
      datadogRum.addAction(`Error Obtained ${error}`);
      datadogRum.addAction("navigating to ( /qr-business )");
      if (isWebTokenExpired) {
        navigate({ pathname: `/qr-reverify` }, { replace: true });
      } else {
        navigate({ pathname: "/qr-business" }, { replace: true });
        toast.error(error);
      }
    }
  }, [error, apiErrorFlag, navigate, toast, destroyMedia, isWebTokenExpired]);

  useEffect(() => {
    if (connected && !isJoining && twilioVideoToken && region && roomName) {
      connectToRoom(twilioVideoToken, region, roomName);
    }
  }, [connected, isJoining, connectToRoom, twilioVideoToken, region, roomName]);

  useEffect(() => {
    if (!connecting && room?.state === "connected") {
      datadogRum.addAction("call connected navigating to ( /qr-room )", room);
      navigate({ pathname: "/qr-room" }, { replace: true });
    }
  }, [room, navigate, connecting]);

  const handleRejectCall = useCallback(() => {
     datadogRum.addAction("call rejected by deaf user")
     reject();
     EventTracker.trackEvent('cancelCallFromQueue', { position:position });
  }, [reject, position]);

  return (
    <ErrorBoundary>
      <Container>
        <SelfView />
        <ConnectingContainer>
          <CircularProgress size={40} color="success" thickness={4} />
          <QueueTextContainer>
            <p aria-label="Please wait for the next available interpreter">Please wait for the next available interpreter...</p>
          </QueueTextContainer>
        </ConnectingContainer>
        <EndButtonContainer>
          {/* <Fab className="normalBtn">
          <VideocamIcon
            sx={{
              color: "black",
            }} />
        </Fab>
        <Fab className="normalBtn">
          <MicIcon
            sx={{
              color: "black",
            }} />
        </Fab> */}
          <Fab className="cancelBtn" onClick={handleRejectCall} aria-label="Cancel call">
            {/* <CloseIcon
            sx={{
              color: "white",

            }} /> */}
            <LazyLoadImage src={cancelIcon} />
          </Fab>
        </EndButtonContainer>
      </Container>
    </ErrorBoundary>
  );
};

export default Connecting;
