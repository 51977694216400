class EventTracker {

  static setVisitorId(visitorId: string): void {
    window.pendoVisitorId = visitorId;
  }

  static identifyUser(visitorId: string, accountId: string | null, visitorData: Record<string, any> = {}, accountData: Record<string, any> = {}): void {
    if (!window.pendo) {
      console.warn('Pendo is not initialized');
      return;
    }

    const visitor = {
      id: visitorId,
      environment: process.env.REACT_APP_ENV,
      ...visitorData,
    };

    const account = { id: accountId, ...accountData };

    window.pendo.identify({ visitor, account });
  }

  static handleUserIdentification(visitorId: string, accountId: string, visitorData: Record<string, any>, accountData: Record<string, any>): void {
    this.setVisitorId(visitorId);

    if (!window.pendoVisitorId) {
      console.warn('Visitor ID is not set');
      return;
    }

    try {
      this.identifyUser(visitorId, accountId, visitorData, accountData);
    } catch (error) {
      console.error('Error identifying Pendo user:', error);
    }
  }

  static setAccountData(requestId: string | null, requestType?: string): void {
    const visitorData = {};
    const accountData = { requestId, requestType };

    this.identifyUser(window.pendoVisitorId, null, visitorData, accountData);
  }

  static trackEvent(eventName: string, eventData: Record<string, any>): void {
    if (!window.pendo) {
      console.warn('Pendo is not initialized');
      return;
    }

    window.pendo.track(eventName, eventData);
  }

}

export default EventTracker;
